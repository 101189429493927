<!--  -->
<template>
  <div class="home">
       <el-upload
            class="video-uploader"
            :action="qiniuForm.uploadUrl"
            :accept="'video/*'"
            :data="{'token':qiniuForm.videoToken}"
            :show-file-list="false"
            :on-success="uploadVideoSuccess"
            :on-error="uploadVideoError"
            :before-upload="beforeVideoUpload">
        </el-upload>

        <el-upload
            class="avatar-uploader"
            :action="qiniuForm.uploadUrl"
            :accept="'image/*'"
            :data="{'token':qiniuForm.imgToken}"
            :show-file-list="false"
            :on-success="uploadEditorSuccess"
            :on-error="uploadEditorError"
            :before-upload="beforeEditorUpload">
        </el-upload>

     <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="标题" label-width="80px" prop="title">
        <el-input v-model="form.title" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="文章类型" label-width="80px" prop="type">
        <el-select v-model="form.type" placeholder="请选择" style="width:100%" >
          <el-option
            v-for="(item, i) in options"
            :key="i"
            :label="item"
            :value="i"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div id="quill_box"></div>
    <el-button type="warning" round class="btn" @click="submit" :loading="loading">修改文章</el-button>
  </div>
</template>

<script>
import { editArticle,getArticleDetail ,getImgToken,getVideoToken } from "@/api/app";
import Quill from "quill";
import { setToken } from "@/utils/auth";
import {
    Message
} from 'element-ui'
let toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ["image"],
    ['video'],
];
export default {
  data() {
    return {
      id:0,
      content:"",
      loading:false,
      form: {
        title: "",
        type:""
      },
      options: {
        1: "企业文化",
        2: "团队绩效",
        3: "业务技巧",
        4: "新人培训",
        5: "管理层领导力",
        6: "人才战略",
        7: "经营实学",
        8: "创业札记",
        9: "软件使用教程",
        10: "独家合同",
        11: "书库",
        12: "版本更新记录",
        13: "对接人公式",
      },
      rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'change' },
          ],
          type: [
            { required: true, message: '请选择文章类型', trigger: 'change' }
          ]
    },
      qiniuForm: {
        imgToken: "",
        videoToken:"",
        uploadUrl: "http://up.qiniup.com",
        domain: 'http://temporary.hengruidichan.com/', //临时域名
      },
    }
  },
  computed: {},
  watch: {},
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    submit(){
          this.$refs.form.validate((valid) => {
          if (valid) {
            var params = {
              Content: this.setImg(this.content),
              Title:this.form.title,
              Type:Number(this.form.type),
              Id:Number(this.id)
            }
            this.loading = true;
               console.log("params-------------------------------------------->",JSON.stringify(params))
       editArticle(params).then((resp) => {
         console.log(resp)
            Message({
          message: '修改成功!',
          type: 'success'
        });
             this.loading = false;
    }).catch(e=>{
      console.log(e)
      this.loading = false;
    })

          } else {
            return false;
          }
        });
    },
    setImg(json){
      for(var i=0;i<json.length;i++){
           if(json[i].insert && json[i].insert.image){
              let str = json[i].insert.image;
              
              if(str.indexOf("http://file.hengruidichan.com/img/") != -1){
                str = str.replace("http://file.hengruidichan.com/img/","");
               }else if(str.indexOf("http://file.haipuwang.net/img/") != -1){
                str = str.replace("http://file.haipuwang.net/img/","");
               }
              //  if(str.indexOf("http://file.hengruidichan.com/img/") != -1){
              //   str = str.replace("http://file.hengruidichan.com/img/","");
              //  }else if(str.indexOf("http://file.haipuwang.net/img/") != -1){
              //   str = str.replace("http://file.haipuwang.net/img/","");
              //  }
            json[i].insert.image = str
         }  
       }
       return json
    },
    getDetail(){
     getArticleDetail(this.id).then((resp)=>{
        this.form.title = resp.Title;
        this.form.type = resp.Type.toString();
       let json = JSON.parse(resp.Content);
       for(var i=0;i<json.length;i++){
           if(json[i].insert && json[i].insert.image){
            json[i].insert.image = `${process.env.VUE_APP_CDNURL}${json[i].insert.image}`
            console.log(json[i].image);
         }  
       }
      for(let i=0;i<json.length;i++){
           if(json[i].insert && json[i].insert.video){
            json[i].insert.video = `${process.env.VUE_APP_CDNURL}${json[i].insert.video}`
            console.log(json[i].video);
         }  
       }
        this.quill.setContents(json);
    });
    },
     //上传图片之前
        beforeEditorUpload(){

            //显示上传动画
            this.quillUpdateImg = true;
        },

        // 上传图片成功
        uploadEditorSuccess(res) {

            //拼接出上传的图片在服务器的完整地址
            let imgUrl = this.qiniuForm.domain+ res.key;

            //重置上传文件key，为下次上传做好准备
            this.qiniuForm.key =  new Date().getTime()+""+Math.floor(Math.random()*1000);

            // 获取富文本组件实例
            let quill = this.quill;

           // 获取光标所在位置
            let length = quill.getSelection().index;
          console.log("image----",imgUrl);
            // 插入图片  res.info为服务器返回的图片地址
            // quill.insertEmbed(length, '\n')
            quill.insertEmbed(length, 'image', imgUrl)

            // 添加样式
            quill.formatText(length,1,{"width":'100%',})
  
            // quill.insertEmbed(length,'br')
            // 调整光标到最后
            quill.setSelection(length + 1)
           
            //取消上传动画
            this.quillUpdateImg = false;
            
        },
        
        // 上传图片失败
        uploadEditorError() {
            //页面提示
            this.$message.error('上传图片失败');

            //取消上传动画
            this.quillUpdateImg = false;
        },
        uploadVideoSuccess(res){
            let videoUrl = this.qiniuForm.domain+ res.key;
            this.qiniuForm.key =  new Date().getTime()+""+Math.floor(Math.random()*1000);
            let quill = this.quill;
            let length = quill.getSelection().index;
            quill.insertEmbed(length, 'video', videoUrl,)
            quill.formatText(length,1,{"width":'100%',"controls":"controls"})
            quill.setSelection(length + 1)
            this.quillUpdateImg = false;
        },
        uploadVideoError(){
             //页面提示
            this.$message.error('上传视频失败');

            //取消上传动画
            this.quillUpdateImg = false;
        },
        beforeVideoUpload(res){
             console.log("上传视频之前----",res)
             //显示上传动画
            this.quillUpdateImg = true;
        },
  },
  mounted() {
    this.id = this.getQueryVariable("Id");
    let token = this.getQueryVariable("Token");
    setToken(token);
   
    this.quill = new Quill("#quill_box", {
      theme: "snow",
      modules: {
        // toolbar: toolbarOptions,
           toolbar: {
                            container: toolbarOptions,  // 工具栏
                            handlers: {
                                'image': function (value) {
                                    if (value) {
                                        document.querySelector('.home .avatar-uploader input').click()
                                    } else {
                                        this.quill.format('image', false);
                                    }
                                },
                                'video':function(value) {
                                    if (value) {
                                        document.querySelector('.home .video-uploader input').click()
                                    } else {
                                        this.quill.format('video', false);
                                    }
                                },
                            }
                        }
      },
    });
     this.getDetail();
     this.quill.on('text-change', (delta, oldDelta, source) => {
       console.log(delta, oldDelta, source)
      this.content = this.quill.getContents().ops;
     });
         getImgToken().then((resp) => {
      this.qiniuForm.imgToken = resp.Token;
    });
    getVideoToken().then((resp)=>{
      this.qiniuForm.videoToken = resp.Token;
    })
  },
};
</script>
<style lang='scss'>
.home {
  // width: 100%;
  height: 100%;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 15px;
}
.btn{
  margin: 20px 0;
}
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
  object-fit:fill;
	width:400px;
	height:320px;
  margin: 10px 0;
}
</style>